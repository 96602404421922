import React from "react";
import { Header, Segment, Button } from "semantic-ui-react";
import firebase from "firebase";
import "firebase/auth";
import { ROUTES } from "../const";
import { Link } from "react-router-dom";

export default class UnauthorizedPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
                <Segment textAlign="center" style={{
                    width: "40vw",
                    padding: "50px"
                }}>
                    <Header size="huge">Not Authorized!</Header>
                    <p>Whoops! It looks like you don't have permissions to do that. Contact an administrator if you don't think this should happen.</p>
                    <Link to={ROUTES.home}><Button primary icon="arrow left" content="Go back" labelPosition="left" /></Link>
                    <Button icon="sign out" content="Switch account" labelPosition="left" onClick={() => {
                        firebase.auth().signOut().then(() => {
                            window.location.href = ROUTES.auth + "?dest=" + encodeURIComponent("/");
                        });
                    }} />
                </Segment>
            </div>
        );
    }
}