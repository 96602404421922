import React from "react";
import PropTypes from "prop-types";

import firebase from "firebase";
import "firebase/firestore";
import { ROUTES } from "../const";
import { Dimmer, Loader, Header, Input, Icon, Popup, Button } from "semantic-ui-react";
import Navbar from "../components/Navbar";
import SaveableInput from "../components/SaveableInput";

class AdminPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            loading: false,
            newSubjectCode: "",
            newSubjectName: ""
        };
        this.db = firebase.firestore();
        this.fetchData();
    }

    fetchData = async () => {
        let doc;
        try {
            doc = await this.db.doc("public/global").get();
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            return;
        }
        this.setState({data: doc.data()});
    }

    updateData = (changes) => {
        this.setState({loading: true});
        this.db.doc("public/global").update(changes).then(() => {
            this.fetchData();
            this.setState({loading: false});
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        });
    }

    deleteSubject = (key) => {
        if(!window.confirm(`Really delete subject ${key}? Make sure you've changed the subject field of any mentors or students teaching/learning this subject.`)) return;
        this.updateData({
            [`courses.${key}`]: firebase.firestore.FieldValue.delete()
        });
    }
    
    render(){
        const {data} = this.state;
        if(data == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            )
        }
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <Navbar />
                <div className="rails">
                    <Header size="huge" textAlign="center">Admin Settings</Header>
                    <Header size="large">Matching Criteria Importances</Header>
                    <p>Certain criteria cause penalties that affect matching. These penalty values correlate to how important the factor is.</p>
                    <SaveableInput defaultValue={data.pointMultipliers.distance.toString()} type="number" label="Location" onSave={(val) => {
                        this.updateData({
                            "pointMultipliers.distance": parseInt(val)
                        });
                    }} />
                    <p>How many penalty points should be added per mile apart.</p>
                    <SaveableInput defaultValue={data.pointMultipliers.mentorGender.toString()} type="number" label="Mentor gender" onSave={(val) => {
                        this.updateData({
                            "pointMultipliers.mentorGender": parseInt(val)
                        });
                    }} />
                    <p>How many penalty points should be added if the student does not work well with the mentor's gender.</p>
                    <SaveableInput defaultValue={data.pointMultipliers.studentGender.toString()} type="number" label="Student gender" onSave={(val) => {
                        this.updateData({
                            "pointMultipliers.studentGender": parseInt(val)
                        });
                    }} />
                    <p>How many penalty points should be added if the mentor does not work well with the student's gender.</p>
                    <SaveableInput defaultValue={data.pointMultipliers.proficiency.toString()} type="number" label="Proficiency" onSave={(val) => {
                        this.updateData({
                            "pointMultipliers.proficiency": parseInt(val)
                        });
                    }} />
                    <p>This many points will be added, times the difference in proficiency rating between the mentor and student.</p>
                    <SaveableInput defaultValue={data.pointMultipliers.school.toString()} type="number" label="School" onSave={(val) => {
                        this.updateData({
                            "pointMultipliers.school": parseInt(val)
                        });
                    }} />
                    <p>How many points should be added if the student and mentor's schools are different.</p>
                    <Header size="large">Subjects</Header>
                    <ul>
                        {
                            Object.keys(data.courses).map(key => {
                                return (
                                    <li>
                                        <Popup trigger={
                                            <Icon name="trash alternate" onClick={() => {
                                                this.deleteSubject(key);
                                            }} />
                                        } inverted position="top center">Delete subject</Popup>
                                        <b>{data.courses[key]}</b> (<code>{key}</code>)
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <Input placeholder="Subject code" value={this.state.newSubjectCode} onChange={(e, props) => {
                        this.setState({newSubjectCode: props.value});
                    }} />
                    <Input placeholder="Subject display name" value={this.state.newSubjectName} onChange={(e, props) => {
                        this.setState({newSubjectName: props.value});
                    }} />
                    <Button circular positive icon="plus" disabled={this.state.newSubjectCode == "" || this.state.newSubjectName == ""} onClick={() => {
                        this.updateData({
                            [`courses.${this.state.newSubjectCode}`]: this.state.newSubjectName
                        });
                    }} />
                    <Header size="large">Trusted Users</Header>
                    <p>These users are allowed to view and edit all data except these admin settings.</p>
                    <ul>
                        {
                            data.allowed.map(email => {
                                return (
                                    <li>
                                        <Popup trigger={
                                            <Icon name="minus circle" onClick={() => {
                                                this.updateData({
                                                    allowed: firebase.firestore.FieldValue.arrayRemove(email)
                                                });
                                            }} />
                                        } inverted position="top center">Remove trusted user</Popup>
                                        {email}
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <SaveableInput type="email" label="Add trusted user" placeholder="john.doe@example.com" icon="plus" onSave={(val) => {
                        if(val.trim().length == 0) return;
                        this.updateData({
                            allowed: firebase.firestore.FieldValue.arrayUnion(val.trim().toLowerCase())
                        });
                    }} />
                    <Header size="large">Administrators</Header>
                    <p>Administrators are allowed to edit admin settings and add other admins. Be very careful with who you authorize!</p>
                    <ul>
                        {
                            data.admins.map(email => {
                                return (
                                    <li>
                                        <Popup trigger={
                                            <Icon name="minus circle" onClick={() => {
                                                this.updateData({
                                                    admins: firebase.firestore.FieldValue.arrayRemove(email)
                                                });
                                            }} />
                                        } inverted position="top center">Remove admin</Popup>
                                        {email}
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <SaveableInput type="email" label="Add admin" placeholder="john.doe@example.com" icon="plus" onSave={(val) => {
                        if(val.trim().length == 0) return;
                        this.updateData({
                            admins: firebase.firestore.FieldValue.arrayUnion(val.trim().toLowerCase())
                        });
                    }} />
                </div>
            </div>
        );
    }
}

AdminPage.propTypes = {
    
};

export default AdminPage;