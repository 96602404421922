import React from "react";
import PropTypes from "prop-types";
import "./styles/LandingPage.scss";
import { Header, Icon, Image } from "semantic-ui-react";
import Logo from "../resources/logo.jpg";

class LandingPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div>
                <div className="split-view">
                    <div>
                        <Image circular centered alt="Leaders United for Change logo" src={Logo} />
                    </div>
                    <div id="flex-buttons">
                        <a href="/students" className="unstyled">
                            <div>
                                <Header size="huge" as="h2">Student Portal</Header>
                                <Icon name="chevron right" />
                            </div>
                        </a>
                        <a href="/parents" className="unstyled">
                            <div>
                                <Header size="huge" as="h2">Parent Portal</Header>
                                <Icon name="chevron right" />
                            </div>
                        </a>
                        <a href="/mentors" className="unstyled">
                            <div>
                                <Header size="huge" as="h2">Mentor Portal</Header>
                                <Icon name="chevron right" />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

LandingPage.propTypes = {
    
};

export default LandingPage;