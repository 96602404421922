import React from "react";
import PropTypes from "prop-types";
import Navbar from "../components/Navbar";
import { Segment, Header, Button, Divider, Card, Icon, Dimmer, Loader, Dropdown, Popup, Input } from "semantic-ui-react";

import firebase from "firebase";
import "firebase/firestore";
import "firebase/functions";
import { ROUTES, LOCKED_COLOR, UNASSIGNED_COLOR, ABSENT_COLOR } from "../const";
import {validateEmail} from "../util";
import SaveableInput from "../components/SaveableInput";

class DashboardPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            globalData: null,
            mentors: [],
            students: [],
            parents: [],
            loading: false,
            subjectFilter: "",
            schoolFilter: "",
            emailAddressField: ""
        };
        this.db = firebase.firestore();
        this.functions = firebase.functions();
        if(process.env.REACT_APP_USE_FIREBASE_EMULATOR == "true") this.functions.useFunctionsEmulator("http://localhost:5001");
        this.fetchGlobalData();
    }

    fetchGlobalData = () => {
        this.db.doc("public/global").get().then(doc => {
            this.setState({globalData: doc.data()});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    addMentor = async () => {
        if(this.state.loading) return;
        if(!validateEmail(this.state.emailAddressField)) return;
        this.setState({loading: true});
        const fn = this.functions.httpsCallable("createAccount");
        let res;
        try {
            res = await fn({
                type: "mentor",
                email: this.state.emailAddressField
            });
        } catch(e) {
            console.error(e);
            this.setState({loading: false});
            return;
        }
        window.location.href = `${ROUTES.mentor}/${res.data.uid}`;
    }

    addStudent = async () => {
        if(this.state.loading) return;
        if(!validateEmail(this.state.emailAddressField)) return;
        this.setState({loading: true});
        const fn = this.functions.httpsCallable("createAccount");
        let res;
        try {
            res = await fn({
                type: "student",
                email: this.state.emailAddressField
            });
        } catch(e) {
            console.error(e);
            this.setState({loading: false});
            return;
        }
        window.location.href = `${ROUTES.student}/${res.data.uid}`;
    }

    addParent = async () => {
        if(this.state.loading) return;
        if(!validateEmail(this.state.emailAddressField)) return;
        this.setState({loading: true});
        const fn = this.functions.httpsCallable("createAccount");
        let res;
        try {
            res = await fn({
                type: "parent",
                email: this.state.emailAddressField
            });
        } catch(e) {
            console.error(e);
            this.setState({loading: false});
            return;
        }
        window.location.href = `${ROUTES.parent}/${res.data.uid}`;
    }

    fetchMentors = () => {
        this.setState({loading: true});
        let ref = this.db.collection("mentors");
        if(this.state.subjectFilter !== ""){
            ref = ref.where("proficiencies." + this.state.subjectFilter, ">", 0);
        }
        if(this.state.schoolFilter !== ""){
            ref = ref.where("school", "==", this.state.schoolFilter);
        }
        ref.get().then((docs) => {
            const mentors = docs.docs.map(i => {
                return {
                    id: i.id,
                    ...i.data()
                };
            });
            this.setState({mentors, loading: false});
        }, (e) => {
            console.error(e);
            if(e.code == "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    fetchStudents = () => {
        this.setState({loading: true});
        let ref = this.db.collection("students");
        if(this.state.subjectFilter !== ""){
            ref = ref.where("subject", "==", this.state.subjectFilter);
        }
        if(this.state.schoolFilter !== ""){
            ref = ref.where("school", "==", this.state.schoolFilter);
        }
        ref.get().then((docs) => {
            const students = docs.docs.map(i => {
                return {
                    id: i.id,
                    ...i.data()
                };
            });
            this.setState({students, loading: false});
        }, (e) => {
            console.error(e);
            if(e.code == "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    fetchParents = () => {
        this.setState({loading: true});;
        this.db.collection("parents").get().then((docs) => {
            const parents = docs.docs.map(i => {
                return {
                    id: i.id,
                    ...i.data()
                };
            });
            this.setState({parents, loading: false});
        }, (e) => {
            console.error(e);
            if(e.code == "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }
    
    render(){
        const segmentStyle = {
            width: "100%",
            margin: "0 5px",
            minHeight: "90vh"
        }
        const {globalData} = this.state;
        if(this.state.globalData == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        const courseChoices = Object.keys(globalData.courses).map(i => {
            return {
                key: i,
                value: i,
                text: globalData.courses[i]
            };
        });
        return (
            <div>
                <Navbar />
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100vw",
                    justifyContent: "stretch",
                    overflowX: "hidden"
                }}>
                    <Segment style={segmentStyle} textAlign="center">
                        <Header size="large">Mentors</Header>
                        <Popup on="click" position="bottom center" trigger={
                            <Button size="big" fluid positive labelPosition="left" icon="add user" content="Add mentor" loading={this.state.loading} />
                        }>
                            <Input type="email" placeholder="Email address" value={this.state.emailAddressField} onChange={(e, props) => {
                                this.setState({emailAddressField: props.value});
                            }} action={
                                <Button positive icon="check" onClick={this.addMentor} disabled={!validateEmail(this.state.emailAddressField)} loading={this.state.loading} />
                            } />
                        </Popup>
                        <Divider />
                        {
                            this.state.mentors.length == 0 ? (
                                <Button size="big" fluid labelPosition="left" icon="refresh" content="Load mentors list" loading={this.state.loading} onClick={this.fetchMentors} />
                            ) : (
                                <Card.Group>
                                    {
                                        this.state.mentors.map(i => {
                                            let subjects = "No subjects";
                                            let students = "no one";
                                            if(Object.keys(i.proficiencies).length > 0){
                                                subjects = Object.keys(i.proficiencies).map(subject => this.state.globalData.courses[subject]).reduce((a, b) => a + ", " + b);
                                            }
                                            if(i.students.length > 0){
                                                students = i.students.map(stu => stu.name).reduce((a, b) => a + ", " + b);
                                            }
                                            let locked = i.students.length > 0;
                                            for(let j of i.students){
                                                if(!j.locked){
                                                    locked = false;
                                                    break;
                                                }
                                            }
                                            let color = null;
                                            if(!i.attending) color = ABSENT_COLOR;
                                            else if(i.students.length == 0) color = UNASSIGNED_COLOR;
                                            else if(locked) color = LOCKED_COLOR;
                                            return (
                                                <Card fluid style={{textAlign: "left", backgroundColor: color}} key={"mentor-" + i.id}>
                                                    <Card.Content>
                                                        <Card.Header>
                                                            <a className="unstyled" href={`${ROUTES.mentor}/${i.id}`}>
                                                                {i.name}
                                                            </a>
                                                        </Card.Header>
                                                        <Card.Meta>{subjects}</Card.Meta>
                                                        <Card.Description>
                                                            Mentoring {students} 
                                                            {
                                                                locked ? <Icon color="olive" name="lock" /> : null
                                                            }
                                                            <br />
                                                            {i.school} • Grade {i.grade} • {i.gender} • <code>{i.id}</code>
                                                            {
                                                                i.notes.length > 0 ? (
                                                                    <>
                                                                        <Divider />
                                                                        {i.notes}
                                                                    </>
                                                                ) : null
                                                            }
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            );
                                        })
                                    }
                                </Card.Group>
                            )
                        }
                    </Segment>
                    <Segment style={segmentStyle} textAlign="center">
                        <Header size="large">Students</Header>
                        <Popup on="click" position="bottom center" trigger={
                            <Button size="big" fluid positive labelPosition="left" icon="add user" content="Add student" loading={this.state.loading} />
                        }>
                            <Input type="email" placeholder="Email address" value={this.state.emailAddressField} onChange={(e, props) => {
                                this.setState({emailAddressField: props.value});
                            }} action={
                                <Button positive icon="check" onClick={this.addStudent} disabled={!validateEmail(this.state.emailAddressField)} loading={this.state.loading} />
                            } />
                        </Popup>
                        <Divider />
                        {
                            this.state.students.length == 0 ? (
                                <Button size="big" fluid labelPosition="left" icon="refresh" content="Load students list" loading={this.state.loading} onClick={this.fetchStudents} />
                            ) : (
                                <Card.Group>
                                    {
                                        this.state.students.map(i => {
                                            let color = null;
                                            if(!i.attending) color = ABSENT_COLOR;
                                            else if(i.mentor == null) color = UNASSIGNED_COLOR;
                                            else if(i.locked) color = LOCKED_COLOR;

                                            let subject = "";
                                            if(i.subject != "") subject = this.state.globalData.courses[i.subject];

                                            return (
                                                <Card fluid style={{textAlign: "left", backgroundColor: color}} key={"student-" + i.id}>
                                                    <Card.Content>
                                                        <Card.Header>
                                                            <a className="unstyled" href={`${ROUTES.student}/${i.id}`}>
                                                                {i.name}
                                                            </a>
                                                        </Card.Header>
                                                        <Card.Meta>{subject}</Card.Meta>
                                                        <Card.Description>
                                                            {
                                                                i.mentor == null ? "Not assigned" : `Mentor: ${i.mentor.name}`
                                                            }
                                                            {
                                                                i.locked ? <Icon color="olive" name="lock" /> : null
                                                            }
                                                            <br />
                                                            {i.school} • Grade {i.grade} • {i.gender} • <code>{i.id}</code>
                                                            {
                                                                i.notes.length > 0 ? (
                                                                    <>
                                                                        <Divider />
                                                                        {i.notes}
                                                                    </>
                                                                ) : null
                                                            }
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            );
                                        })
                                    }
                                </Card.Group>
                            )
                        }
                    </Segment>
                    <Segment style={segmentStyle} textAlign="center">
                        <Header size="large">Parents</Header>
                        <Popup on="click" position="bottom center" trigger={
                            <Button size="big" fluid positive labelPosition="left" icon="add user" content="Add parent" loading={this.state.loading} />
                        }>
                            <Input type="email" placeholder="Email address" value={this.state.emailAddressField} onChange={(e, props) => {
                                this.setState({emailAddressField: props.value});
                            }} action={
                                <Button positive icon="check" onClick={this.addParent} disabled={!validateEmail(this.state.emailAddressField)} loading={this.state.loading} />
                            } />
                        </Popup>
                        <Divider />
                        {
                            this.state.parents.length == 0 ? (
                                <Button size="big" fluid labelPosition="left" icon="refresh" content="Load parents list" loading={this.state.loading} onClick={this.fetchParents} />
                            ) : (
                                <Card.Group>
                                    {
                                        this.state.parents.map(i => {
                                            let students = "No students";
                                            if(i.students.length > 0){
                                                students = i.students.map(stu => stu.name).reduce((a, b) => a + ", " + b);
                                            }
                                            return (
                                                <Card fluid style={{textAlign: "left"}} key={"parent-" + i.id}>
                                                    <Card.Content>
                                                        <Card.Header>
                                                            <a className="unstyled" href={`${ROUTES.parent}/${i.id}`}>
                                                                {i.name}
                                                            </a>
                                                        </Card.Header>
                                                        <Card.Meta>{students}</Card.Meta>
                                                    </Card.Content>
                                                </Card>
                                            );
                                        })
                                    }
                                </Card.Group>
                            )
                        }
                    </Segment>
                </div>
            </div>
        );
    }
}

DashboardPage.propTypes = {
    
};

export default DashboardPage;