import "./styles/AuthPage.scss";
import React from "react";
import PropTypes from "prop-types";
import { Loader, Dimmer, Header, Image, Button, Divider, Segment, Input, Message, Popup } from "semantic-ui-react";

import firebase from "firebase";
import "firebase/auth";

import Logo from "../resources/logo.jpg";
import { PUBLIC_URL } from "../const";
import { getSearchParam, validateEmail } from "../util";

class AuthPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            error: null,
            emailText: "",
            passwordText: "",
            passwordResetSuccess: false
        };
        this.auth = firebase.auth();
        const destParam = getSearchParam("dest");
        this.dest = decodeURIComponent(destParam);
        if(destParam == null || destParam == undefined || destParam.length == 0){
            this.state.error = "no_dest";
        }
    }

    signIn = () => {
        this.setState({loading: true});
        
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            this.auth.signInWithEmailAndPassword(this.state.emailText, this.state.passwordText).then(() => {
                window.location.replace(this.dest);
            }, e => {
                console.error(e);
                switch(e.code){
                    case "auth/invalid-email":
                        this.setState({error: "The email you entered is invalid. Check for mistakes and try again.", loading: false});
                        break;
                    case "auth/user-disabled":
                        this.setState({error: "Your account has been disabled. Please contact us for assistance.", loading: false});
                        break;
                    case "auth/user-not-found":
                        this.setState({error: "We couldn't find your account. Please verify your email address is correct.", loading: false});
                        break;
                    case "auth/wrong-password":
                        this.setState({error: "The password you entered is incorrect. Check for mistakes and try again.", loading: false});
                        break;
                    default:
                        this.setState({error: `An unknown error occured. Please try again later. (${e.code})`, loading: false});
                        break;
                }
            });
        }, (e) => {
            console.error(e);
            this.setState({loading: false, error: `Unexpected error: ${e.code}`});
        });
    }

    sendPasswordResetEmail = () => {
        if(this.state.passwordResetSuccess) return;
        if(!validateEmail(this.state.emailText)) {
            this.setState({error: "Please enter the email address associated with your account."});
            return;
        }
        this.setState({loading: true, passwordResetSuccess: false});
        this.auth.sendPasswordResetEmail(this.state.emailText).then(() => {
            this.setState({loading: false, passwordResetSuccess: true});
        }, (e) => {
            console.error(e);
            this.setState({loading: false, error: `Unexpected error: ${e.code}`});
        });
    }
    
    render(){
        const emailIsValid = validateEmail(this.state.emailText);
        const passwordIsValid = this.state.passwordText.length > 0;

        return (
            <div>
                <div className="split-view">
                    <div>
                        <Image circular centered alt="Leaders United for Change logo" src={Logo} />
                    </div>
                    <div>
                        <Header id="main-header">Welcome back!</Header>
                        <Divider hidden />
                        <Segment id="sign-in-panel" compact>
                            <Header size="huge" style={{textAlign: "center"}}>Sign In</Header>
                            <div id="split">
                                <div id="inputs">
                                    <Input error={!emailIsValid} disabled={this.state.loading} size="big" type="email" placeholder="Email address" value={this.state.emailText} onChange={(e, data) => this.setState({emailText: data.value})} />
                                    <Divider hidden fitted />
                                    <Input error={!passwordIsValid} disabled={this.state.loading} size="big" type="password" placeholder="Password" value={this.state.passwordText} onChange={(e, data) => this.setState({passwordText: data.value})} />
                                </div>
                                <Button circular icon="arrow right" primary size="massive" disabled={!emailIsValid || !passwordIsValid} loading={this.state.loading} onClick={this.signIn} />
                            </div>
                            <div id="footer">
                                <p>
                                    <a onClick={this.sendPasswordResetEmail}>Forgot password?</a>
                                </p>
                                <Popup trigger={
                                    <p>Don't have an account?</p>
                                }>When you join Leaders United for Change, an account will be created for you.</Popup>
                            </div>
                        </Segment>
                        {this.state.error == null ? null : (
                            <Message compact negative content={this.state.error} />
                        )}
                        <Divider hidden />
                        {this.state.passwordResetSuccess ? (
                            <Message compact info content={"We've sent instructions to reset your password to your email."} />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

AuthPage.propTypes = {
    
};

export default AuthPage;