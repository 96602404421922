import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import firebase from "firebase";
import "firebase/firestore";
import { Header, Dimmer, Loader, Divider, Segment, Checkbox, Dropdown, TextArea, Button, Card, Rating, Icon, Popup, Message } from "semantic-ui-react";
import Navbar from "../components/Navbar";
import { ROUTES, genderOptions, LOCKED_COLOR } from "../const";
import SaveableInput from "../components/SaveableInput";
import { Route, Link } from "react-router-dom";

class MentorPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            globalData: null,
            loading: false,
            newNotes: ""
        };
        this.db = firebase.firestore();
        this.functions = firebase.functions();
        if(process.env.REACT_APP_USE_FIREBASE_EMULATOR == "true") this.functions.useFunctionsEmulator("http://localhost:5001");
        this.id = this.props.match.params.id;
        if(this.id === null || this.id === undefined || this.id === ""){
            console.log("No ID specified. Returning home");
            window.location.replace(ROUTES.dashboard);
            return;
        }
        this.fetchData();
        this.fetchGlobalData();
    }

    fetchGlobalData = () => {
        this.db.doc("public/global").get().then(doc => {
            this.setState({globalData: doc.data()});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    fetchData = () => {
        this.db.collection("mentors").doc(this.id).get().then(doc => {
            if(!doc.exists){
                console.log("Doc does not exist.");
                window.location.replace(ROUTES.dashboard);
                return;
            }
            const data = doc.data();
            this.setState({data, newNotes: data.notes});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    updateData = (changes) => {
        this.setState({loading: true});
        this.db.collection("mentors").doc(this.id).update(changes).then(() => {
            this.fetchData();
            this.setState({loading: false});
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        });
    }

    deleteMentor = async () => {
        if(!window.confirm("Really delete mentor " + this.state.data.name + "?")){
            return;
        }
        this.setState({loading: true});
        let snap;
        try {
            snap = await this.db.collection("students").where("mentor.id", "==", this.id).get();
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
            return;
        }
        for(const doc of snap.docs){
            await doc.ref.update({
                mentor: null,
                locked: false
            });
        }
        await this.db.collection("mentors").doc(this.id).delete();
        const fn = this.functions.httpsCallable("deleteUser");
        try {
            const res = await fn({uid: this.id});
            console.log(res.data);
        } catch(e) {
            console.error(e);
            this.setState({loading: false});
            return;
        }
        window.location.replace(ROUTES.dashboard);
    }

    unpairStudent = async (student) => {
        await this.setState({loading: true});
        try {
            await this.db.collection("students").doc(student.id).update({
                mentor: null,
                locked: false
            });
            await this.db.collection("mentors").doc(this.id).update({
                students: firebase.firestore.FieldValue.arrayRemove(student),
                previousPairs: firebase.firestore.FieldValue.arrayUnion({
                    id: student.id,
                    name: student.name
                })
            });
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        }
        this.fetchData();
        this.setState({loading: false});
    }

    lockStudent = async (student) => {
        await this.setState({loading: true});
        try {
            await this.db.collection("students").doc(student.id).update({
                locked: !student.locked
            });
            await this.db.collection("mentors").doc(this.id).update({
                students: firebase.firestore.FieldValue.arrayRemove(student)
            });
            student.locked = !student.locked;
            await this.db.collection("mentors").doc(this.id).update({
                students: firebase.firestore.FieldValue.arrayUnion(student)
            });
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        }
        this.fetchData();
        this.setState({loading: false});
    }
    
    addStudent = (studentId) => {
        
        const data = this.state.data;
        
        if(data.students.findIndex(i => i.id == studentId) !== -1){
            return;
        }
        
        this.setState({loading: true});
        const studentRef = this.db.collection("students").doc(studentId);
        const mentorRef = this.db.collection("mentors").doc(this.id);
        studentRef.get().then(studentDoc => {
            if(!studentDoc.exists){
                alert("No student found with that ID.");
                this.setState({loading: false});
                return;
            }
            const studentData = studentDoc.data();
            mentorRef.update({
                students: firebase.firestore.FieldValue.arrayUnion({
                    name: studentData.name,
                    id: studentDoc.id,
                    locked: false
                })
            }).then(() => {
                studentRef.update({
                    mentor: {
                        name: data.name,
                        id: this.id,
                    },
                    locked: false
                }).then(() => {
                    this.fetchData();
                    this.setState({loading: false});
                }, (e) => {
                    console.error(e);
                    if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
                    this.setState({loading: false});
                });
            }, (e) => {
                console.error(e);
                if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
                this.setState({loading: false});
            });
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        })
    }

    render(){
        const {data, globalData} = this.state;
        if(data == null || globalData == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }

        const courseChoices = Object.keys(globalData.courses).map(i => {
            return {
                key: i,
                value: i,
                text: globalData.courses[i]
            };
        });

        const addedOn = moment(data.addedOn.toDate());
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <Navbar />
                <Header size="huge" style={{textAlign: "center"}}>
                    <span style={{fontWeight: "lighter"}}>Mentor:</span> {data.name}
                </Header>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "62fr 38fr",
                    width: "100vw",
                    overflowX: "hidden",
                    padding: "30px 15vw"
                }}>
                    <div>
                        <Header size="small">Attending</Header>
                        <Checkbox toggle label="Is this mentor attending this week?" checked={data.attending} onChange={(e, props) => {
                            this.updateData({
                                attending: props.checked
                            });
                        }} />
                        <Divider hidden />
                        <Header size="small">Videoconferencing</Header>
                        <Checkbox toggle label="Assign videoconferencing info" checked={data.videoconferencingInfo !== null} onChange={(e, props) => {
                            this.updateData({
                                videoconferencingInfo: props.checked ? {} : null
                            });
                        }} />
                        {
                            data.videoconferencingInfo == null ? null : (
                                <>
                                    <div style={{marginTop: "5px"}} />
                                    <SaveableInput label="URL" defaultValue={data.videoconferencingInfo.url} onSave={(val) => {
                                        this.updateData({"videoconferencingInfo.url": val});
                                    }} />
                                    <div style={{marginTop: "5px"}} />
                                    <SaveableInput label="Password" defaultValue={data.videoconferencingInfo.password} onSave={(val) => {
                                        this.updateData({"videoconferencingInfo.password": val});
                                    }} />
                                    <div style={{marginTop: "5px"}} />
                                    <SaveableInput label="Assigned breakout room" defaultValue={data.videoconferencingInfo.breakoutRoom} onSave={(val) => {
                                        this.updateData({"videoconferencingInfo.breakoutRoom": val});
                                    }} />
                                </>
                            )
                        }
                        <Divider hidden />
                        <Header size="small">Name</Header>
                        <SaveableInput defaultValue={data.name} onSave={(val) => {
                            this.updateData({
                                name: val
                            });
                        }} />
                        <Divider hidden />
                        <Header size="small">Grade level</Header>
                        <SaveableInput defaultValue={data.grade.toString()} type="number" onSave={(val) => {
                            this.updateData({
                                grade: parseInt(val)
                            });
                        }} />
                        <Divider hidden />
                        <Header size="small">School</Header>
                        <SaveableInput defaultValue={data.school} onSave={(val) => {
                            this.updateData({
                                school: val
                            });
                        }} />
                        <Divider hidden />
                        <Header size="small">Approximate Location</Header>
                        <p>If precise location is unknown, use the location of their city.</p>
                        <SaveableInput defaultValue={data.location.latitude.toString()} type="number" label="Latitude" min={-90} max={90} step={.0000001} onSave={(val) => {
                            if(val < -90 || val > 90) return;
                            this.updateData({
                                location: new firebase.firestore.GeoPoint(parseFloat(val), data.location.longitude)
                            });
                        }} />
                        <div style={{marginTop: "5px"}} />
                        <SaveableInput defaultValue={data.location.longitude.toString()} type="number" label="Longitude" min={-180} max={180} step={.0000001} onSave={(val) => {
                            if(val < -180 || val > 180) return;
                            this.updateData({
                                location: new firebase.firestore.GeoPoint(data.location.latitude, parseFloat(val))
                            });
                        }} />
                        <Divider hidden />
                        <Header size="small">Gender</Header>
                        <Dropdown placeholder="Gender" selection options={genderOptions} value={data.gender} onChange={(e, props) => {
                            this.updateData({
                                gender: props.value
                            });
                        }} />
                        <Divider hidden />
                        <Header size="small">Preferred genders</Header>
                        <Dropdown placeholder="Preferred genders" multiple selection options={genderOptions} value={data.preferredGender} onChange={(e, props) => {
                            this.updateData({
                                preferredGender: props.value
                            });
                        }} />
                        <p>Select which genders the person works best with.</p>
                        <Divider hidden />
                        <Header size="small">Subjects</Header>
                        <Dropdown options={courseChoices.filter(i => !(i.value in data.proficiencies))} value="" placeholder="Add a subject" onChange={(e, props) => {
                            this.updateData({
                                [`proficiencies.${props.value}`]: 1
                            });
                        }} style={{marginBottom: "30px"}} />
                        <Divider hidden />
                        <Card.Group>
                            {
                                Object.keys(data.proficiencies).sort().map(key => {
                                    return (
                                        <Card>
                                            <Card.Content>
                                                <Popup trigger={
                                                    (
                                                        <Icon style={{
                                                            float: "right"
                                                        }} name="minus circle" onClick={() => {
                                                            this.updateData({
                                                                [`proficiencies.${key}`]: firebase.firestore.FieldValue.delete()
                                                            });
                                                        }} />
                                                    )
                                                } inverted position="top center">
                                                    Remove subject
                                                </Popup>
                                                <Card.Header>{globalData.courses[key]}</Card.Header>
                                                <Card.Description>
                                                    <span style={{marginRight: "5px"}}>Proficiency: </span>
                                                    <Rating icon="star" rating={data.proficiencies[key]} maxRating={5} onRate={(e, props) => {
                                                        this.updateData({
                                                            [`proficiencies.${key}`]: props.rating
                                                        });
                                                    }} />
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    );
                                })
                            }
                        </Card.Group>
                        {
                            Object.keys(data.proficiencies).length == 0 ? (
                                <Message icon="warning" warning content="No subjects!" compact />
                            ) : null
                        }
                        <Divider hidden />
                        <Header size="small">Previous Pairings</Header>
                        <p>{data.name} has been paired with these students before but was not locked in before reshuffling. This mentor will never be paired with these students again.</p>
                        <Card.Group>
                            {
                                data.previousPairs.map(i => {
                                    return (
                                        <Card>
                                            <Card.Content>
                                                <Popup trigger={
                                                    (
                                                        <Icon style={{
                                                            float: "right"
                                                        }} name="minus circle" onClick={() => {
                                                            this.updateData({
                                                                previousPairs: firebase.firestore.FieldValue.arrayRemove(i)
                                                            });
                                                        }} />
                                                    )
                                                } inverted position="top center">
                                                    Remove previous pairing
                                                </Popup>
                                                <Card.Header>
                                                    <Link to={`${ROUTES.student}/${i.id}`}>{i.name}</Link>
                                                </Card.Header>
                                                <Card.Meta><code>{i.id}</code></Card.Meta>
                                            </Card.Content>
                                        </Card>
                                    );
                                })
                            }
                        </Card.Group>
                        <Divider hidden />
                        <Header size="small">Capacity</Header>
                        <SaveableInput type="number" defaultValue={data.capacity} onSave={(val) => {
                            this.updateData({
                                capacity: val
                            });
                        }} />
                        <p>How many students can this mentor teach at a time?</p>
                        <Divider />
                        <p>Added on {addedOn.format("MMMM Do, YYYY [at] h:mm A.")}</p>
                        <p>ID # <code>{this.id}</code></p>
                        <Divider />
                        <TextArea value={this.state.newNotes} onChange={(e, props) => {
                            this.setState({newNotes: props.value});
                        }} />
                        <br />
                        <Button icon="check" content="Save notes" labelPosition="left" positive onClick={() => {
                            this.updateData({
                                notes: this.state.newNotes
                            });
                        }} />
                        <Divider />
                        <Button negative icon="delete user" labelPosition={"left"} content="Delete mentor" onClick={this.deleteMentor} />
                    </div>
                    <Segment raised>
                        <Header size="large" textAlign="center">Current Assignments</Header>
                        <SaveableInput fluid label="Manually assign" positive placeholder="ID # of student" icon="plus" onSave={(val) => {
                            this.addStudent(val);
                        }} />
                        <Divider section />
                        <Card.Group>
                            {
                                data.students.sort((a, b) => (a.id < b.id) ? -1 : 1).map(i => {
                                    return (
                                        <Card fluid style={{
                                            backgroundColor: i.locked ? LOCKED_COLOR : null
                                        }} key={`match-${i.id}`}>
                                            <Card.Content>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "space-between"
                                                }}>
                                                    <div>
                                                        <Card.Header>
                                                            <Link to={`${ROUTES.student}/${i.id}`}>{i.name}</Link>
                                                        </Card.Header>
                                                        <Card.Meta><code>{i.id}</code></Card.Meta>
                                                    </div>
                                                    <div>
                                                        <Popup trigger={
                                                            (
                                                                <Button size="medium" icon={i.locked ? "lock" : "lock open"} primary circular onClick={() => {
                                                                    this.lockStudent(i);
                                                                }} />
                                                            )
                                                        } inverted position="top center">
                                                            Pairing is {i.locked ? "locked in" : "not locked in"}. Click to {i.locked ? "unlock" : "lock"}
                                                        </Popup>
                                                        <Popup trigger={
                                                            (
                                                                <Button size="medium" icon="minus circle" negative circular onClick={() => {
                                                                    this.unpairStudent(i);
                                                                }} />
                                                            )
                                                        } inverted position="top center">
                                                            Unpair with student
                                                        </Popup>
                                                    </div>
                                                </div>
                                            </Card.Content>
                                        </Card>
                                    );
                                })
                            }
                        </Card.Group>
                    </Segment>
                </div>
            </div>
        );
    }
}

MentorPage.propTypes = {
    
};

export default MentorPage;