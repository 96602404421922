import React from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { ROUTES } from "../const";

import firebase from "firebase";
import "firebase/auth";

class Navbar extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    handleClick = (e, {name}) => {
        window.location.href = ROUTES[name];
    }

    signOut = () => {
        firebase.auth().signOut().then(() => {

        }, console.error);
    }

    render(){
        const route = window.location.pathname;
        return (
            <Menu>
                <Menu.Item
                    name="dashboard"
                    active={route == ROUTES.dashboard}
                    onClick={this.handleClick}
                >
                    Dashboard
                </Menu.Item>
                <Menu.Item
                    name="match"
                    active={route == ROUTES.match}
                    onClick={this.handleClick}
                >
                    Match
                </Menu.Item>
                <Menu.Item
                    name="admin"
                    active={route == ROUTES.admin}
                    onClick={this.handleClick}
                >
                    Admin
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item onClick={this.signOut}>
                        Sign out
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}

Navbar.propTypes = {
    
};

export default Navbar;