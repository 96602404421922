import React from 'react';
import "semantic-ui-css/semantic.min.css";
import './App.scss';
import firebase from "firebase";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { ROUTES, firebaseConfig } from './const';
import DashboardPage from './pages/DashboardPage';
import AdminPage from './pages/AdminPage';
import MatchPage from './pages/MatchPage';
import MentorPage from './pages/MentorPage';
import StudentPage from './pages/StudentPage';
import ParentPage from './pages/ParentPage';
import PrivateRoute from './components/PrivateRoute';
import AuthPage from './pages/AuthPage';
import UnauthorizedPage from './pages/UnauthorizedPage';
import LandingPage from './pages/LandingPage';
import StudentPortal from './pages/StudentPortal';
import ParentPortal from './pages/ParentPortal';
import MentorPortal from './pages/MentorPortal';

export default class App extends React.Component {
	constructor(props){
		super(props);
		if(firebase.apps.length <= 0){
			firebase.initializeApp(firebaseConfig);
		}
		if(process.env.REACT_APP_USE_FIREBASE_EMULATOR == "true") {
			console.log("%c *****\nApp is using Firebase Emulators.\n*****", "background: #000777000; color: #fc6621;");
		}
	}

	render(){
		return (
			<Router>
				<Switch>
					<PrivateRoute path={ROUTES.admin} component={AdminPage} />
					<PrivateRoute path={ROUTES.match} component={MatchPage} />
					<PrivateRoute path={ROUTES.mentor + "/:id"} component={MentorPage} />
					<PrivateRoute path={ROUTES.student + "/:id"} component={StudentPage} />
					<PrivateRoute path={ROUTES.parent + "/:id"} component={ParentPage} />
					<Route path={ROUTES.auth} component={AuthPage} />
					<Route path={ROUTES.unauthorized} component={UnauthorizedPage} />
					<PrivateRoute exact path={ROUTES.dashboard} component={DashboardPage} />
					<PrivateRoute path={ROUTES.studentPortal + "/:id?"} component={StudentPortal} />
					<PrivateRoute path={ROUTES.parentPortal + "/:id?"} component={ParentPortal} />
					<PrivateRoute path={ROUTES.mentorPortal + "/:id?"} component={MentorPortal} />
					<Route path={ROUTES.home} exact component={LandingPage} />
				</Switch>
			</Router>
		);
	}
}