import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase";
import "firebase/firestore";
import { Card, Dimmer, Divider, Header, Loader } from "semantic-ui-react";
import { ROUTES } from "../const";
import "./styles/PortalPages.scss";

export default class ParentPortal extends React.Component {
    constructor(props){
        super(props);
        this.state = {data: null};
        this.id = this.props.match.params.id == null ? this.props.user.uid : this.props.match.params.id;
        this.db = firebase.firestore();
        this.fetchData();
    }
    
    fetchData = () => {
        this.db.collection("parents").doc(this.id).get().then(doc => {
            if(!doc.exists){
                alert("Error: parent " + this.id + " does not exist");
                window.location.replace(ROUTES.home);
                return;
            }
            const data = doc.data();
            this.setState({data});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    render(){
        const {data} = this.state;
        if(data == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <div className="rails">
                    <h1 id="main-header">Parent Portal</h1>
                    <h2 id="subheader">👋 Hi, {data.name}!</h2>
                    <Divider section hidden />
                    <p className="large center-aligned">To view and manage your linked students, choose a profile below.</p>
                    <Divider horizontal section>
                        <Header as="h3" icon="users" content="Student Profiles" />
                    </Divider>
                    <Card.Group>
                        {data.students.map(student => (
                            <Link to={`${ROUTES.studentPortal}/${student.id}`}>
                                <Card key={student.id} header={student.name} />
                            </Link>
                        ))}
                    </Card.Group>
                </div>
            </div>
        );
    }
}