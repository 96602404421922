import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import firebase from "firebase";
import "firebase/firestore";
import { Header, Dimmer, Loader, Divider, Segment, TextArea, Button, Icon, Popup, Message, Card } from "semantic-ui-react";
import Navbar from "../components/Navbar";
import { ROUTES } from "../const";
import SaveableInput from "../components/SaveableInput";
import { Route, Link } from "react-router-dom";

class ParentPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            globalData: null,
            loading: false,
            newNotes: ""
        };
        this.db = firebase.firestore();
        this.functions = firebase.functions();
        if(process.env.REACT_APP_USE_FIREBASE_EMULATOR == "true") this.functions.useFunctionsEmulator("http://localhost:5001");
        this.id = this.props.match.params.id;
        if(this.id === null || this.id === undefined || this.id === ""){
            console.log("No ID specified. Returning home");
            window.location.replace(ROUTES.dashboard);
            return;
        }
        this.fetchData();
        this.fetchGlobalData();
    }

    fetchGlobalData = () => {
        this.db.doc("public/global").get().then(doc => {
            this.setState({globalData: doc.data()});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    fetchData = () => {
        this.db.collection("parents").doc(this.id).get().then(doc => {
            if(!doc.exists){
                console.log("Doc does not exist.");
                window.location.replace(ROUTES.dashboard);
                return;
            }
            const data = doc.data();
            this.setState({data, newNotes: data.notes});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    updateData = (changes) => {
        this.setState({loading: true});
        this.db.collection("parents").doc(this.id).update(changes).then(() => {
            this.fetchData();
            this.setState({loading: false});
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        });
    }

    deleteParent = async () => {
        if(!window.confirm("Really delete parent " + this.state.data.name + "?")){
            return;
        }
        this.setState({loading: true});
        
        const promises = this.state.data.students.map(student => {
            return this.db.collection("students").doc(student.id).update({
                parentUids: firebase.firestore.FieldValue.arrayRemove(this.id)
            });
        });
        await Promise.all(promises);
        try {
            await this.db.collection("parents").doc(this.id).delete();
        } catch(e){ 
            console.error(e);
            this.setState({loading: false});
            return;
        }
        const fn = this.functions.httpsCallable("deleteUser");
        try {
            const res = await fn({uid: this.id});
            console.log(res.data);
        } catch(e) {
            console.error(e);
            this.setState({loading: false});
            return;
        }
        window.location.replace(ROUTES.dashboard);
    }
    
    addStudent = (studentId) => {
        const data = this.state.data;
        
        if(data.students.findIndex(i => i.id == studentId) !== -1){
            return;
        }
        this.setState({loading: true});
        const studentRef = this.db.collection("students").doc(studentId);
        const parentRef = this.db.collection("parents").doc(this.id);
        studentRef.get().then(studentDoc => {
            if(!studentDoc.exists){
                alert("No student found with that ID.");
                this.setState({loading: false});
                return;
            }
            const studentData = studentDoc.data();
            parentRef.update({
                students: firebase.firestore.FieldValue.arrayUnion({
                    name: studentData.name,
                    id: studentDoc.id
                })
            }).then(() => {
                studentRef.update({
                    parentUids: firebase.firestore.FieldValue.arrayUnion(this.id)
                }).then(() => {
                    this.fetchData();
                    this.setState({loading: false});
                }, (e) => {
                    console.error(e);
                    if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
                    this.setState({loading: false});
                });
            }, (e) => {
                console.error(e);
                if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
                this.setState({loading: false});
            });
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        })
    }

    unpairStudent = (studentId) => {
        const studentRef = this.db.collection("students").doc(studentId);
        const parentRef = this.db.collection("parents").doc(this.id);
        const studentToRemove = this.state.data.students.find(i => i.id == studentId);
        if(!studentToRemove) {
            console.error("Student to remove is undefined.");
            return;
        }
        this.setState({loading: true});
        parentRef.update({
            students: firebase.firestore.FieldValue.arrayRemove(studentToRemove)
        }).then(() => {
            studentRef.update({
                parentUids: firebase.firestore.FieldValue.arrayRemove(this.id)
            }).then(() => {
                this.fetchData();
                this.setState({loading: false});
            });
        }, e => {
            console.error(e);   
            this.setState({loading: false});
        });
    }

    render(){
        const {data, globalData} = this.state;
        if(data == null || globalData == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }

        const addedOn = moment(data.addedOn.toDate());
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <Navbar />
                <Header size="huge" style={{textAlign: "center"}}>
                    <span style={{fontWeight: "lighter"}}>Parent:</span> {data.name}
                </Header>
                <div className="rails">
                    <Header size="small">Students</Header>
                    <SaveableInput fluid label="Link student" positive placeholder="ID # of student" icon="plus" onSave={(val) => {
                        this.addStudent(val);
                    }} />
                    <Divider hidden />
                    <Card.Group>
                        {
                            data.students.sort((a, b) => (a.id < b.id) ? -1 : 1).map(i => {
                                return (
                                    <Card fluid key={`student-${i.id}`}>
                                        <Card.Content>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <div>
                                                    <Card.Header>
                                                        <Link to={`${ROUTES.student}/${i.id}`}>{i.name}</Link>
                                                    </Card.Header>
                                                    <Card.Meta><code>{i.id}</code></Card.Meta>
                                                </div>
                                                <div>
                                                    <Popup trigger={
                                                        (
                                                            <Button size="medium" icon="minus circle" negative circular onClick={() => {
                                                                this.unpairStudent(i.id);
                                                            }} />
                                                        )
                                                    } inverted position="top center">
                                                        Unlink student
                                                    </Popup>
                                                </div>
                                            </div>
                                        </Card.Content>
                                    </Card>
                                );
                            })
                        }
                    </Card.Group>
                    <Divider hidden />
                    <Header size="small">Name</Header>
                    <SaveableInput defaultValue={data.name} onSave={(val) => {
                        this.updateData({
                            name: val
                        });
                    }} />
                    <Divider />
                    <p>Added on {addedOn.format("MMMM Do, YYYY [at] h:mm A.")}</p>
                    <p>ID # <code>{this.id}</code></p>
                    <Divider />
                    <TextArea value={this.state.newNotes} onChange={(e, props) => {
                        this.setState({newNotes: props.value});
                    }} />
                    <br />
                    <Button icon="check" content="Save notes" labelPosition="left" positive onClick={() => {
                        this.updateData({
                            notes: this.state.newNotes
                        });
                    }} />
                    <Divider />
                    <Button negative icon="delete user" labelPosition={"left"} content="Delete parent" onClick={this.deleteParent} />
                </div>
            </div>
        );
    }
}

ParentPage.propTypes = {
    
};

export default ParentPage;