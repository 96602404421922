import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import "firebase/firestore";
import * as geometry from 'spherical-geometry-js';
import { Button, Segment, Divider, Header, Icon } from "semantic-ui-react";
import Navbar from "../components/Navbar";
import { ROUTES } from "../const";
import { Link } from "react-router-dom";

class MatchPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            status: "Make sure all of the matches you want to keep are locked in!",
            loading: false,
            success: false
        };
    }

    startMatching = async () => {
        const db = firebase.firestore();
        await this.setState({loading: true, status: "Fetching admin settings..."});
        let globalSnap;
        try {
            globalSnap = await db.doc("public/global").get();
        } catch(e) {
            this.handleError(e);
            return;
        }
        const globalData = globalSnap.data();
        await this.setState({status: "Fetching students and mentors list..."});
        let studentsSnap;
        let mentorsSnap;
        try {
            studentsSnap = await db.collection("students").where("locked", "==", false).where("attending", "==", true).get();
            mentorsSnap = await db.collection("mentors").where("attending", "==", true).get(); // A where command is not available due to the data structure. Filter on client side
        } catch(e) {
            this.handleError(e);
            return;
        }
        const studentsArr = studentsSnap.docs.map(doc => [doc.id, doc.data()]);
        const mentorsArr = mentorsSnap.docs.map(doc => [doc.id, doc.data()]).filter(mentor => {
            if(mentor[1].students.length < mentor[1].capacity) return true;
            for(const pair of mentor[1].students){
                if(!pair.locked){
                    return true;
                }
            }
            return false;
        });
        const students = Object.fromEntries(studentsArr);
        const mentors = Object.fromEntries(mentorsArr);
        let points = [];
        this.setState({status: "Calculating point values..."});
        for(const i of Object.keys(mentors)) {
            for(const j of Object.keys(students)) {
                let pts = 0;
                const student = students[j];
                const mentor = mentors[i];
                if(!(student.subject in mentor.proficiencies)){
                    pts = -10001;
                }else if(mentor.previousPairs.findIndex(n => n.id == j) !== -1){
                    pts = -10000;
                }else{
                    if(!mentor.preferredGender.includes(student.gender)) pts -= globalData.pointMultipliers.studentGender;
                    if(!student.preferredGender.includes(mentor.gender)) pts -= globalData.pointMultipliers.mentorGender;
                    if(student.school.trim().toLowerCase() !== mentor.school.trim().toLowerCase() || student.school.length == 0 || mentor.school.length == 0){
                        pts -= globalData.pointMultipliers.school;
                    }
                    pts -= Math.abs(student.proficiency - mentor.proficiencies[student.subject]) * globalData.pointMultipliers.proficiency;

                    const mentorGeo = new geometry.LatLng(mentor.location.latitude, mentor.location.longitude);
                    const studentGeo = new geometry.LatLng(student.location.latitude, student.location.longitude);
                    const dist = geometry.computeDistanceBetween(mentorGeo, studentGeo);
                    pts -= dist * globalData.pointMultipliers.distance / 1609;
                }
                points.push({
                    student: j,
                    mentor: i,
                    points: pts
                });
            }
        }
        // Have list of teacher remaining capacities.
        // Have one flat array of objects with {mentor, student, score}; sort from greatest to least.
        // In order, loop thru the flat array; starting from the top, create the matches.
            // If a match cannot be created bc remaining capacity is too low, delete the match
            // If remaining students w/o match, cry (jk they'll be highlighted in red anyways)
        this.setState({status: "Determining best matches..."});
        points.sort((a, b) => a.points - b.points);

        const capacities = {};
        for(const key of Object.keys(mentors)){
            capacities[key] = mentors[key].capacity - mentors[key].students.filter(i => i.locked).length;
        }
        const matchedStudents = {};
        for(const key of Object.keys(students)){
            matchedStudents[key] = false;
        }
        let savedMatches = [];
        for(const i of points){
            if(matchedStudents[i.student]){
                continue;
            }
            if(capacities[i.mentor] <= 0){
                continue;
            }
            if(i.points <= -10000){
                continue;
            }
            capacities[i.mentor]--;
            matchedStudents[i.student] = true;
            savedMatches.push(i);
        }
        console.log(points);

        this.setState({status: "Saving best matches..."});
        const promises = [];
        for(const id of Object.keys(students)){
            const found = savedMatches.find(i => i.student == id);
            let newMentor;
            if(found == undefined){
                newMentor = null;
            }else{
                newMentor = {
                    name: mentors[found.mentor].name,
                    id: found.mentor
                };
            }
            const p = db.collection("students").doc(id).update({
                mentor: newMentor,
                locked: false
            });
            promises.push(p);
        }
        for(const id of Object.keys(mentors)){
            const oldData = mentors[id];
            const found = savedMatches.filter(i => i.mentor == id);
            let newStudents = found.map(i => {
                return {
                    locked: false,
                    id: i.student,
                    name: students[i.student].name 
                };
            });
            newStudents.concat(oldData.students.filter(i => i.locked));
            const previousPairs = oldData.students.filter(i => !i.locked).map(i => {
                return {
                    id: i.id,
                    name: i.name
                };
            });
            const updateData = {
                students: newStudents
            };
            if(previousPairs.length > 0){
                updateData.previousPairs = firebase.firestore.FieldValue.arrayUnion(...previousPairs);
            }
            const p = db.collection("mentors").doc(id).update(updateData);
            promises.push(p);
        }
        try {
            await Promise.all(promises);
        } catch(e) {
            this.handleError(e);
            return;
        }
        console.log(savedMatches);
        this.setState({loading: false, success: true});
    }

    handleError = (e) => {
        console.error(e);
        if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        this.setState({loading: false, status: "An error occured. Please try again."});
    }
    
    render(){
        if(this.state.success){
            return (
                <div>
                    <Navbar />
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "90vh"}}>
                        <Segment textAlign="center" basic>
                            <Header size="huge" icon>
                                <Icon name="check" />
                                Matching complete!
                            </Header>
                            <Divider hidden />
                            <Link to={ROUTES.dashboard}>
                                <Button primary size="huge" circular icon="dashboard" labelPosition="left" content="Back to dashboard" />
                            </Link>
                        </Segment>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <Navbar />
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "90vh"}}>
                    <Segment textAlign="center" basic>
                        <Button positive size="massive" circular icon="random" labelPosition="left" content="Start Matching!" loading={this.state.loading} disabled={this.state.loading} onClick={this.startMatching} />
                        <Divider hidden />
                        <p>{this.state.status}</p>
                    </Segment>
                </div>
            </div>
        );
    }
}

MatchPage.propTypes = {
    
};

export default MatchPage;