import React from "react";
import {Route} from "react-router-dom";
import PropTypes from "prop-types";
import firebase from "firebase";
import {ROUTES} from "../const";
import { Loader, Dimmer } from "semantic-ui-react";
import {updateQueryString} from "../util";

class PrivateRoute extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            authenticated: false,
            user: null
        };

        firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({
					authenticated: true,
                    user: user,
                    loading: false
				})
			}else{
				this.setState({
					authenticated: false,
                    user: null,
                    loading: false
				});
			}
		});
    }
    
    render(){

        const {authenticated, user, loading} = this.state;
        if(loading){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        if(!authenticated) {
            window.location.replace(updateQueryString("dest", encodeURIComponent(window.location.pathname + window.location.search), ROUTES.auth));
            return <Loader />;
        }
        const MyComponent = this.props.component;
        return (
            <Route path={this.props.path} render={(props) => {
                return (
                    <MyComponent {...props} user={user} />
                );
            }} />
        );
    }
}

PrivateRoute.propTypes = {
    path: PropTypes.string,
    component: PropTypes.element
};

export default PrivateRoute;