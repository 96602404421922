export const ROUTES = {
    "home": "/",
    "dashboard": "/dashboard",
    "mentor": "/mentor",
    "student": "/student",
    "parent": "/parent",
    "match": "/match",
    "admin": "/admin",
    "auth": "/auth",
    "studentPortal": "/students",
    "parentPortal": "/parents",
    "mentorPortal": "/mentors",
    "unauthorized": "/unauthorized"
};

export const firebaseConfig = {
    apiKey: "AIzaSyCaaOJtOpXlgAfMFRgIpKLbO1zBvqO5yHc",
    authDomain: "luc-match.firebaseapp.com",
    databaseURL: "https://luc-match.firebaseio.com",
    projectId: "luc-match",
    storageBucket: "luc-match.appspot.com",
    messagingSenderId: "693565929826",
    appId: "1:693565929826:web:6e081c12c5cd86dd10543b"
};

export const PUBLIC_URL = "https://leadersunitedforchange.org/";

export const genderOptions = [
    {
        key: "male",
        text: "Male",
        value: "male"
    },
    {
        key: "female",
        text: "Female",
        value: "female"
    },
    {
        key: "other",
        text: "Other",
        value: "other"
    }
];

export const LOCKED_COLOR = "#fafffa";
export const UNASSIGNED_COLOR = "#fff0f0";
export const ABSENT_COLOR = "#bdbdbd";