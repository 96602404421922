import React from "react";
import PropTypes from "prop-types";
import { Input, Button } from "semantic-ui-react";

class SaveableInput extends React.Component {
    static defaultProps = {
        defaultValue: "",
        placeholder: "",
        icon: "check",
        color: null,
        positive: "auto",
        label: null,
        fluid: false,
        min: null,
        max: null,
        step: null
    };

    constructor(props){
        super(props);
        this.state = {
            value: this.props.defaultValue
        };
    }

    componentDidUpdate(prevProps){
        if(this.props.defaultValue != prevProps.defaultValue){
            this.setState({value: this.props.defaultValue});
        }
    }
    
    render(){
        const valueChanged = this.state.value != this.props.defaultValue;
        return (
            <Input min={this.props.min} max={this.props.max} step={this.props.step} fluid={this.props.fluid} label={this.props.label} type={this.props.type} placeholder={this.props.placeholder} value={this.state.value} onChange={(e, props) => {
                this.setState({value: props.value});
            }} action={(
                <Button positive={this.props.positive === true || (this.props.positive == "auto" && valueChanged)} color={this.props.color} icon={this.props.icon} onClick={() => {
                    this.props.onSave(this.state.value);
                }} />
            )} />
        );
    }
}

SaveableInput.propTypes = {
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    positive: PropTypes.oneOfType(PropTypes.bool, PropTypes.string),
    label: PropTypes.string,
    type: PropTypes.string,
    fluid: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onSave: PropTypes.func.isRequired
};

export default SaveableInput;