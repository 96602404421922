import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import firebase from "firebase";
import "firebase/firestore";
import { Header, Dimmer, Loader, Divider, Segment, Checkbox, Dropdown, TextArea, Button, Card, Rating, Icon, Popup, Message, List } from "semantic-ui-react";
import Navbar from "../components/Navbar";
import { ROUTES, genderOptions, LOCKED_COLOR } from "../const";
import SaveableInput from "../components/SaveableInput";
import { Link } from "react-router-dom";

class StudentPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            globalData: null,
            loading: false,
            newNotes: ""
        };
        this.db = firebase.firestore();
        this.functions = firebase.functions();
        if(process.env.REACT_APP_USE_FIREBASE_EMULATOR == "true") this.functions.useFunctionsEmulator("http://localhost:5001");
        this.id = this.props.match.params.id;
        if(this.id === null || this.id === undefined || this.id === ""){
            console.log("No ID specified. Returning home");
            window.location.replace(ROUTES.dashboard);
            return;
        }
        this.fetchData();
        this.fetchGlobalData();
    }

    fetchGlobalData = () => {
        this.db.doc("public/global").get().then(doc => {
            this.setState({globalData: doc.data()});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    fetchData = () => {
        this.db.collection("students").doc(this.id).get().then(doc => {
            if(!doc.exists){
                console.log("Doc does not exist.");
                window.location.replace(ROUTES.dashboard);
                return;
            }
            const data = doc.data();
            this.setState({data, newNotes: data.notes});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }

    updateData = (changes) => {
        this.setState({loading: true});
        this.db.collection("students").doc(this.id).update(changes).then(() => {
            this.fetchData();
            this.setState({loading: false});
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        });
    }

    deleteStudent = async () => {
        if(!window.confirm("Really delete student " + this.state.data.name + "?")){
            return;
        }
        if(this.state.data.mentor !== null){
            alert("Remove this students' mentor match then try again.");
            return;
        }
        this.setState({loading: true});
        const myObj = {
            name: this.state.data.name,
            id: this.id
        };
        let mentorSnap;
        try {
            mentorSnap = await this.db.collection("mentors").where("previousPairs", "array-contains", myObj).get();
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
            return;
        }
        const promises = mentorSnap.docs.map((doc) => {
            return doc.ref.update({
                previousPairs: firebase.firestore.FieldValue.arrayRemove(myObj)
            });
        });
        try {
            await Promise.all(promises)
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
            return;
        }

        const parentPromises = this.state.data.parentUids.map(async parentUid => {
            const parent = await this.db.collection("parents").doc(parentUid).get();
            const parentData = parent.data();
            const thisStudent = parentData.students.find(i => i.id == this.id);
            if(thisStudent === undefined) {
                console.error(`Parent ${parent.id} does not have student ${this.id} in data.`);
                return;
            }
            await parent.ref.update({
                students: firebase.firestore.FieldValue.arrayRemove(thisStudent)
            });
            return;
        });
        try {
            await Promise.all(parentPromises);
        } catch(e){
            console.error(e);
            this.setState({loading: false});
            return;
        }

        try {
            await this.db.collection("students").doc(this.id).delete();
        } catch(e) {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
            return;
        }

        const fn = this.functions.httpsCallable("deleteUser");
        try {
            const res = await fn({uid: this.id});
            console.log(res.data);
        } catch(e) {
            console.error(e);
            this.setState({loading: false});
            return;
        }
        window.location.href = ROUTES.dashboard;
    }

    render(){
        const {data, globalData} = this.state;
        if(data == null || globalData == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }

        const courseChoices = Object.keys(globalData.courses).map(i => {
            return {
                key: i,
                value: i,
                text: globalData.courses[i]
            };
        });

        const addedOn = moment(data.addedOn.toDate());
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <Navbar />
                <Header size="huge" style={{textAlign: "center"}}>
                    <span style={{fontWeight: "lighter"}}>Student:</span> {data.name}
                </Header>
                <div className="rails">
                    <Header size="small">Attending</Header>
                    <Checkbox toggle label="Is this student attending this week?" checked={data.attending} onChange={(e, props) => {
                        this.updateData({
                            attending: props.checked
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">Mentor Match</Header>
                    {
                        data.mentor == null ? (
                            <Message icon="warning" warning content={
                                <p>Student is unassigned. Use ID <code>{this.id}</code> on a mentor's page to manually add a match.</p>
                            } compact />
                        ) : (
                            <>
                                <Card>
                                    <Card.Content style={{
                                        backgroundColor: data.locked ? LOCKED_COLOR : null
                                    }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <div>
                                                    <Card.Header>
                                                        <Link to={`${ROUTES.mentor}/${data.mentor.id}`}>{data.mentor.name}</Link>
                                                    </Card.Header>
                                                    <Card.Meta><code>{data.mentor.id}</code></Card.Meta>
                                                </div>
                                                <Popup trigger={
                                                    <Icon size="large" name={data.locked ? "lock" : "lock open"} color={data.locked ? "blue" : "black"} />
                                                } inverted position="top center">Match is {data.locked ? "locked in and will stay" : "unlocked and will change"}.</Popup>
                                            </div>
                                    </Card.Content>
                                </Card>
                                <p>Click the link to the mentor page to delete and lock/unlock assignment.</p>
                            </>
                        )
                    }
                    <Divider hidden />
                    <Header size="small">Videoconferencing</Header>
                    <Checkbox toggle label="Assign videoconferencing info" checked={data.videoconferencingInfo !== null} onChange={(e, props) => {
                        this.updateData({
                            videoconferencingInfo: props.checked ? {} : null
                        });
                    }} />
                    {
                        data.videoconferencingInfo == null ? null : (
                            <>
                                <div style={{marginTop: "5px"}} />
                                <SaveableInput label="URL" defaultValue={data.videoconferencingInfo.url} onSave={(val) => {
                                    this.updateData({"videoconferencingInfo.url": val});
                                }} />
                                <div style={{marginTop: "5px"}} />
                                <SaveableInput label="Password" defaultValue={data.videoconferencingInfo.password} onSave={(val) => {
                                    this.updateData({"videoconferencingInfo.password": val});
                                }} />
                                <div style={{marginTop: "5px"}} />
                                <SaveableInput label="Assigned breakout room" defaultValue={data.videoconferencingInfo.breakoutRoom} onSave={(val) => {
                                    this.updateData({"videoconferencingInfo.breakoutRoom": val});
                                }} />
                            </>
                        )
                    }
                    <Divider hidden />
                    <Header size="small">Linked Parent Accounts</Header>
                    <List bulleted>
                        {
                            data.parentUids.map(parentUid => {
                                return (
                                    <List.Item>
                                        <Link to={`${ROUTES.parent}/${parentUid}`}>
                                            <code>{parentUid}</code>
                                        </Link>
                                    </List.Item>
                                );
                            })
                        }
                    </List>
                    <Divider hidden />
                    <Header size="small">Name</Header>
                    <SaveableInput defaultValue={data.name} onSave={(val) => {
                        this.updateData({
                            name: val
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">Grade level</Header>
                    <SaveableInput defaultValue={data.grade.toString()} type="number" onSave={(val) => {
                        this.updateData({
                            grade: parseInt(val)
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">School</Header>
                    <SaveableInput defaultValue={data.school} onSave={(val) => {
                        this.updateData({
                            school: val
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">Approximate Location</Header>
                    <p>If precise location is unknown, use the location of their city.</p>
                    <SaveableInput defaultValue={data.location.latitude.toString()} type="number" label="Latitude" min={-90} max={90} step={.0000001} onSave={(val) => {
                        if(val < -90 || val > 90) return;
                        this.updateData({
                            location: new firebase.firestore.GeoPoint(parseFloat(val), data.location.longitude)
                        });
                    }} />
                    <div style={{marginTop: "5px"}} />
                    <SaveableInput defaultValue={data.location.longitude.toString()} type="number" label="Longitude" min={-180} max={180} step={.0000001} onSave={(val) => {
                        if(val < -180 || val > 180) return;
                        this.updateData({
                            location: new firebase.firestore.GeoPoint(data.location.latitude, parseFloat(val))
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">Gender</Header>
                    <Dropdown placeholder="Gender" selection options={genderOptions} value={data.gender} onChange={(e, props) => {
                        this.updateData({
                            gender: props.value
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">Preferred genders</Header>
                    <Dropdown placeholder="Preferred genders" multiple selection options={genderOptions} value={data.preferredGender} onChange={(e, props) => {
                        this.updateData({
                            preferredGender: props.value
                        });
                    }} />
                    <p>Select which genders the person works best with.</p>
                    <Divider hidden />
                    <Header size="small">Subject</Header>
                    <Dropdown options={courseChoices} value={data.subject} placeholder="Choose a subject" onChange={(e, props) => {
                        this.updateData({
                            subject: props.value
                        });
                    }} />
                    <Divider hidden />
                    <Header size="small">Proficiency</Header>
                    <Rating icon="star" rating={data.proficiency} maxRating={5} onRate={(e, props) => {
                        this.updateData({
                            proficiency: props.rating
                        });
                    }} />
                    <Divider />
                    <p>Added on {addedOn.format("MMMM Do, YYYY [at] h:mm A.")}</p>
                    <p>ID # <code>{this.id}</code></p>
                    <Divider />
                    <TextArea value={this.state.newNotes} onChange={(e, props) => {
                        this.setState({newNotes: props.value});
                    }} />
                    <br />
                    <Button icon="check" content="Save notes" labelPosition="left" positive onClick={() => {
                        this.updateData({
                            notes: this.state.newNotes
                        });
                    }} />
                    <Divider />
                    <Button negative icon="delete user" labelPosition={"left"} content="Delete student" onClick={this.deleteStudent} />
                </div>
            </div>
        );
    }
}

StudentPage.propTypes = {
    
};

export default StudentPage;