import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { ROUTES } from "../const";
import "./styles/PortalPages.scss";
import { Dimmer, Divider, Header, Loader, Message, Segment, Checkbox } from "semantic-ui-react";

export default class StudentPortal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null, loading: false
        };
        this.id = this.props.match.params.id == null ? this.props.user.uid : this.props.match.params.id;
        this.db = firebase.firestore();
        this.fetchData();
    }

    fetchData = () => {
        this.db.collection("students").doc(this.id).get().then(doc => {
            if(!doc.exists){
                alert("Error: student " + this.id + " does not exist");
                window.location.replace(ROUTES.home);
                return;
            }
            const data = doc.data();
            this.setState({data});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }
    
    updateData = (changes) => {
        this.setState({loading: true});
        this.db.collection("students").doc(this.id).update(changes).then(() => {
            this.fetchData();
            this.setState({loading: false});
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        });
    }
    
    render(){
        const {data} = this.state;
        if(data == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        const isParent = data.parentUids.includes(this.props.user.uid);
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <div className="rails">
                    <h1 id="main-header">{isParent ? "Student Profile" : "Student Portal"}</h1>
                    <h2 id="subheader">
                        {isParent ? data.name : `👋 Hi, ${data.name}!`}
                    </h2>
                    <Divider section hidden />
                    <Divider horizontal section>
                        <Header as="h3" icon="video" content="Videoconferencing" />
                    </Divider>
                    <Segment compact textAlign="center" id="videoconferencing-info">
                        <Header size="medium">Videoconferencing Info</Header>
                        {
                            data.videoconferencingInfo == null ? (
                                <p>Not assigned yet! Please check back later.</p>
                            ) : (
                                <div id="videoconferencing-content">
                                    <div>
                                        <p>Link</p>
                                        <p><a href={data.videoconferencingInfo.url} target="_blank" rel="noopener noreferrer">
                                            {data.videoconferencingInfo.url}
                                        </a></p>
                                    </div>
                                    <div>
                                        <p>Password</p>
                                        <p><code>{data.videoconferencingInfo.password}</code></p>
                                    </div>
                                    <div>
                                        <p>Assigned breakout room</p>
                                        <p>{data.videoconferencingInfo.breakoutRoom}</p>
                                    </div>
                                </div>
                            )
                        }
                    </Segment>
                    <Divider horizontal section>
                        <Header as="h3" icon="clipboard list" content="Attendance" />
                    </Divider>
                    <Message
                        color={data.attending ? "olive" : "orange"}
                        size="large"
                        icon={data.attending ? "check" : "times"}
                        content={`${isParent ? `${data.name} is` : `You are`} marked as ${data.attending ? "attending" : "not attending"}.`}
                    />
                    <div className="center-aligned">
                        {
                            isParent ? (
                                <Checkbox toggle label={`Is ${data.name} attending this week?`} checked={data.attending} onChange={(e, props) => {
                                    this.updateData({
                                        attending: props.checked
                                    });
                                }} />
                            ) : (
                                <p>Need to change your attendance? Ask a parent to log into the parent portal.</p>
                            )
                        }
                    </div>
                    <Divider horizontal section>
                        <Header as="h3" icon="clipboard list" content="Mentor" />
                    </Divider>
                    {
                        data.mentor == null ? (
                            <p className="center-aligned">
                                {isParent ? `${data.name} has` : `You have`} not been assigned a mentor this week.
                            </p>
                        ) : (
                            <p className="center-aligned">
                                {isParent ? `${data.name} is` : `You are`} assigned to <b>{data.mentor.name}</b>.
                            </p>
                        )
                    }
                </div>
            </div>
        );
    }
}