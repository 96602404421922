import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { ROUTES } from "../const";
import "./styles/PortalPages.scss";
import { Dimmer, Divider, Header, Loader, Message, Segment, Checkbox, Card } from "semantic-ui-react";

export default class MentorPortal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null, loading: false
        };
        this.id = this.props.match.params.id == null ? this.props.user.uid : this.props.match.params.id;
        this.db = firebase.firestore();
        this.fetchData();
    }

    fetchData = () => {
        this.db.collection("mentors").doc(this.id).get().then(doc => {
            if(!doc.exists){
                alert("Error: mentor " + this.id + " does not exist");
                window.location.replace(ROUTES.home);
                return;
            }
            const data = doc.data();
            this.setState({data});
        }, e => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
        });
    }
    
    updateData = (changes) => {
        this.setState({loading: true});
        this.db.collection("mentors").doc(this.id).update(changes).then(() => {
            this.fetchData();
            this.setState({loading: false});
        }, (e) => {
            console.error(e);
            if(e.code === "permission-denied") window.location.href = ROUTES.unauthorized;
            this.setState({loading: false});
        });
    }
    
    render(){
        const {data} = this.state;
        if(data == null){
            return (
                <Dimmer active>
                    <Loader />
                </Dimmer>
            );
        }
        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader />
                </Dimmer>
                <div className="rails">
                    <h1 id="main-header">Mentor Portal</h1>
                    <h2 id="subheader">👋 Hi, {data.name}!</h2>
                    <Divider section hidden />
                    <Divider horizontal section>
                        <Header as="h3" icon="video" content="Videoconferencing" />
                    </Divider>
                    <Segment compact textAlign="center" id="videoconferencing-info">
                        <Header size="medium">Videoconferencing Info</Header>
                        {
                            data.videoconferencingInfo == null ? (
                                <p>Not assigned yet! Please check back later.</p>
                            ) : (
                                <div id="videoconferencing-content">
                                    <div>
                                        <p>Link</p>
                                        <p><a href={data.videoconferencingInfo.url} target="_blank" rel="noopener noreferrer">
                                            {data.videoconferencingInfo.url}
                                        </a></p>
                                    </div>
                                    <div>
                                        <p>Password</p>
                                        <p><code>{data.videoconferencingInfo.password}</code></p>
                                    </div>
                                    <div>
                                        <p>Assigned breakout room</p>
                                        <p>{data.videoconferencingInfo.breakoutRoom}</p>
                                    </div>
                                </div>
                            )
                        }
                    </Segment>
                    <Divider horizontal section>
                        <Header as="h3" icon="clipboard list" content="Attendance" />
                    </Divider>
                    <Message
                        color={data.attending ? "olive" : "orange"}
                        size="large"
                        icon={data.attending ? "check" : "times"}
                        content={`You are marked as ${data.attending ? "attending" : "not attending"}`}
                    />
                    <div className="center-aligned">
                        <Checkbox toggle label="I am attending this week" checked={data.attending} onChange={(e, props) => {
                            this.updateData({
                                attending: props.checked
                            });
                        }} />
                    </div>
                    <Divider horizontal section>
                        <Header as="h3" icon="clipboard list" content="Students" />
                    </Divider>
                    {
                        data.students.length === 0 ? (
                            <p className="center-aligned">You have not been matched yet.</p>
                        ) : (
                            <div className="center-aligned">
                                <p className="large">You are assigned to:</p>
                                <Card.Group style={{justifyContent: "center"}}>
                                    {
                                        data.students.map(student => (
                                            <Card key={student.id} header={student.name} />
                                        ))
                                    }
                                </Card.Group>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}